import React, { useState } from 'react'
import { PhotoUploadField, ConditionalRootCauseFields } from '../components'
import { Form, Formik } from 'formik'
import {
  SubmitButton,
  FormWideErrorReporter,
  Select,
  RadioGroup,
  Textarea,
} from 'formik-components'
import { buildSelectOptions, checkIfRootCauseNeglectOrDefect } from 'utils'
import { uniq } from 'lodash'
import * as Types from 'types'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const createValidationSchema = (excludedKeys) => {
  const isExcluded = (key) => excludedKeys.includes(key)

  const validationSchema = {
    rootCause: Yup.string().required("Root Cause can't be blank"),
    operable: Yup.string().required("Operable can't be blank"),
    status: Yup.string().required("Work Status can't be blank"),
    notes: Yup.string().required("Notes can't be blank"),
    abuseNeglectClaims: Yup.string().when('rootCause', {
      is: (value) => checkIfRootCauseNeglectOrDefect(value),
      then: () => Yup.string().required("Notes can't be blank"),
    }),
    workPerformedPhotos: Yup.array()
      .min(1, 'Work Performed Photo requires at least 1 photo upload')
      .max(3, 'Work Performed Photo requires between 1 and 3 photo uploads'),
    unitConditionPhotos: Yup.array()
      .min(1, 'Unit Condition Photo requires at least 1 photo upload')
      .max(3, 'Unit Condition Photo requires between 1 and 3 photo uploads'),
    abusePhotos: Yup.array().when('rootCause', {
      is: (value) => Types.ROOT_CAUSE_NEGLECT_OPTIONS.includes(value),
      then: () =>
        Yup.array()
          .min(1, 'Abuse Photo requires at least 1 photo upload')
          .max(3, 'Abuse Photo requires between 1 and 3 photo uploads'),
    }),
    defectPhotos: Yup.array().when('rootCause', {
      is: (value) => value === Types.ROOT_CAUSE_DEFECT,
      then: () =>
        Yup.array()
          .min(1, 'Defect Photo Photo requires at least 1 photo upload')
          .max(3, 'Defect Photo Photo requires between 1 and 3 photo uploads'),
    }),
  }

  // Filter out excluded keys
  const filteredSchema = Object.keys(validationSchema).reduce((acc, key) => {
    if (!isExcluded(key)) {
      acc[key] = validationSchema[key]
    }
    return acc
  }, {})

  return Yup.object(filteredSchema)
}

function WorkOrderCheckoutForm({ initialValues, onSubmit }) {
  const [customFormWideError, setCustomFormWideError] = useState(null)

  const [formKeysSkipValidation, setFormKeysSkipValidation] = useState([])

  const handlePhotoUploadAttempt = (fieldName) => {
    setFormKeysSkipValidation(uniq([...formKeysSkipValidation, fieldName]))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createValidationSchema(formKeysSkipValidation)}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ values, isSubmitting, setFieldError }) => {
        return (
          <Form className="trip-form-container w-100p">
            <FormWideErrorReporter
              key={customFormWideError}
              customFormWideError={customFormWideError}
              setCustomFormWideError={setCustomFormWideError}
            />
            <Select
              name="rootCause"
              label="Root Cause"
              placeholder="Select Root Cause"
              options={buildSelectOptions(Types.ROOT_CAUSE_OPTIONS, true)}
              required
              requiredIndicator="*"
            />
            <RadioGroup
              name="operable"
              label="Operational?"
              options={buildSelectOptions(Types.OPERATIONAL_OPTIONS)}
              required
              requiredIndicator="*"
            />
            <RadioGroup
              name="status"
              label="Work Status"
              options={buildSelectOptions(Types.WORK_STATUS_OPTIONS)}
              required
              requiredIndicator="*"
            />
            <Textarea
              name="notes"
              autoGrow
              label="Notes"
              counter
              placeholder="Work status notes. Note that this is a multi-line field."
              maxlength={Types.WORK_STATUS_CHARACTER_LIMIT}
              required
              requiredIndicator="*"
            />
            <ConditionalRootCauseFields
              rootCauseSelection={values.rootCause}
              isSubmitting={isSubmitting}
              handlePhotoUploadAttempt={handlePhotoUploadAttempt}
              shouldSetCustomFormWideError={true}
              setCustomFormWideError={setCustomFormWideError}
              setFieldError={setFieldError}
            />
            <PhotoUploadField
              constraintText="Please upload 1 to 3 images for this section"
              label="Work Performed Photo"
              name="workPerformedPhotos"
              photoType={Types.PHOTO_TYPE.WORK_PERFORMED}
              required
              requiredIndicator="*"
              isSubmitting={isSubmitting}
              onPhotoUploadAttempt={handlePhotoUploadAttempt}
              shouldSetCustomFormWideError={true}
              setCustomFormWideError={setCustomFormWideError}
              setFieldError={setFieldError}
            />
            <PhotoUploadField
              constraintText="Please upload 1 to 3 images for this section"
              label="Whole Unit Showing Overall Condition and Location Upon Exit Photo"
              name="unitConditionPhotos"
              photoType={Types.PHOTO_TYPE.UNIT_CONDITION}
              required
              requiredIndicator="*"
              isSubmitting={isSubmitting}
              onPhotoUploadAttempt={handlePhotoUploadAttempt}
              shouldSetCustomFormWideError={true}
              setCustomFormWideError={setCustomFormWideError}
              setFieldError={setFieldError}
            />
            <SubmitButton
              expand="full"
              isSubmitting={isSubmitting}
              showSpinner={true}
            >
              Check Out
            </SubmitButton>
          </Form>
        )
      }}
    </Formik>
  )
}

WorkOrderCheckoutForm.propTypes = propTypes
WorkOrderCheckoutForm.defaultProps = defaultProps

export default React.memo(WorkOrderCheckoutForm)
