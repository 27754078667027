import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { storageGet } from 'local-storage'
import { DirectUpload } from 'activestorage'
import { isNil, noop } from 'lodash'
import { FileInput } from 'formik-components'
import { Field } from 'formik'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as Types from 'types'

const propTypes = {
  createTripPhoto: PropTypes.func.isRequired,
  currentTrip: Types.trip.isRequired,
  deleteTripPhoto: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photoType: PropTypes.string.isRequired,
  questionId: PropTypes.string,
  onPhotoUploadAttempt: PropTypes.func,
  setFieldError: PropTypes.func,
}
const defaultProps = {
  onPhotoUploadAttempt: noop,
  setFieldError: noop,
}

function PhotoUploadField({
  createTripPhoto,
  currentTrip,
  deleteTripPhoto,
  label,
  name,
  photoType,
  questionId,
  ...rest
}) {
  const uploadNewPhotos = useCallback(
    async (photosToUpload) => {
      if (photosToUpload.length <= 0) return []

      // A direct upload to S3 consists of three interactions:
      // 1. First, request a signed URL using Active Storage on the
      //    backend API.
      // 2. Next, directly upload the file to S3 using the signed URL.
      // 3. Finally, make a backend API call to associate the uploaded file
      //    (represented by a returned "signedId") to a backend API model
      // .  object representing a trip photo.
      //
      // The first two steps are implemented by the DirectPhotoUpload class
      // (below). This class uses the Rails Active Storage DirectUpload class
      // in its implementation. The last step is a backend API call,
      // createTripPhoto(), that associates the signedId returned from the
      // successful upload to S3 with a TripPhoto model object in the backend
      // API.
      const bearerToken = await storageGet('token')
      const uploadedPhotos = await Promise.all(
        photosToUpload.map(async (photo) => {
          const photoUpload = new DirectPhotoUpload(photo, {
            bearerToken,
          })
          // The DirectPhotoUpload::upload() method performs the first two
          // steps of direct upload: obtaining the signed URL and performing
          // the direct upload of the file to S3
          const signedId = await photoUpload.upload()
          // The createTripPhoto() API performs the final direct upload step:
          // associated the uploaded file with an instance of a TripPhoto
          // in the application database.
          const uploadedTripPhoto = await createTripPhoto(
            currentTrip.id,
            decoratePhotoWithQuestionFields(
              signedId,
              photoType,
              questionId,
              label
            )
          )

          // The filename is "name" in the FileInput
          uploadedTripPhoto.name = uploadedTripPhoto.filename

          return uploadedTripPhoto
        })
      )
      return uploadedPhotos
    },
    [createTripPhoto, currentTrip, label, photoType, questionId]
  )

  const removePhoto = async (photo) => {
    if (isNil(photo)) return

    await deleteTripPhoto(currentTrip.id, photo.id)
  }

  if (isNil(currentTrip)) return null
  return (
    <Field
      component={FileInput}
      accept="image/*"
      multiple={true}
      label={label}
      name={name}
      onFilesReadyToUpload={uploadNewPhotos}
      onRemove={removePhoto}
      {...rest}
    />
  )
}

function decoratePhotoWithQuestionFields(
  signedId,
  photoType,
  questionId,
  questionText
) {
  const decoratedPhoto = { signedId, photoType, questionText }

  if (photoType === Types.PHOTO_TYPE.CUSTOM) {
    decoratedPhoto.questionId = questionId
  }

  return decoratedPhoto
}

class DirectPhotoUpload {
  constructor(file, options) {
    this.options = options
    // The DirectUpload class will invoke DirectPhotoUpload methods to
    // annotate the BE direct upload creation request
    // (directUploadWillCreateBlobWithXHR()) and (in the future) facilitate
    // upload progress indication (directUploadWillStoreFileWithXHR()).
    // The last parameter of the DirectUpload constructor ("this")
    // facilitates such callbacks.
    this.directUpload = new DirectUpload(file, this.directUploadsUrl, this)
  }

  get directUploadsUrl() {
    return `${process.env.API_URL}/api/v1/direct_uploads`
  }

  async getCreateBlobHeaders() {
    return await storageGet('token')
  }

  async upload() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) reject(error)
        else resolve(blob.signed_id)
      })
    })
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    // We added this to add our authorization header when we are getting the signed URL
    // from the server
    this._addHeaders(xhr)
  }

  _addHeaders(xhr) {
    const { bearerToken } = this.options
    if (bearerToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${bearerToken}`)
    }
  }
}

PhotoUploadField.propTypes = propTypes
PhotoUploadField.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentTrip: selectors.currentTrip(state),
  }
}

const mapDispatchToProps = {
  createTripPhoto: apiActions.createTripPhoto,
  deleteTripPhoto: apiActions.deleteTripPhoto,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PhotoUploadField
)
