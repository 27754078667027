/**
 * This hook returns two functions to display submit success and failure messages
 *
 * @name useSubmitMessages
 */

import getFormWideErrorMessage from './getFormWideErrorMessage'
import useToast from './useToast'

let displayedToast = null

function useSubmitMessages() {
  const [showToast] = useToast()

  // clearToasts clears any previously displayed toasts
  // So that they don't stack on top of each other
  function clearToasts() {
    if (!displayedToast) return Promise.resolve()
    return displayedToast.dismiss()
  }

  /**
   * @name displaySubmitSuccess
   * @param {String} message - Message to display
   */
  function displaySubmitSuccess(message) {
    clearToasts().then(() => {
      showToast({
        message,
        color: 'success',
        onDidPresent: (e) => {
          displayedToast = e.target
        },
        duration: 3000,
      })
    })
  }

  /**
   * @name displaySubmitFailure
   * @param {Object} errors - Errors that caused submit to fail
   * @param {Function} dispatch - Redux dispatch function. Not used with toasts.
   * @param {Object} submitError - Errors that caused submit to fail. If the only errors are related internally to Formik validations, this will be null
   */
  function displaySubmitFailure(errors, _, submitError) {
    const message = getFormWideErrorMessage(errors, submitError)
    clearToasts().then(() => {
      showToast({
        message,
        color: 'danger',
        onDidPresent: (e) => {
          displayedToast = e.target
        },
        duration: 10000,
      })
    })
  }

  return { displaySubmitSuccess, displaySubmitFailure, clearToasts }
}

export default useSubmitMessages
