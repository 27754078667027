import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import { Textarea } from 'formik-components'
import PhotoUploadField from './PhotoUploadField'
import * as Types from 'types'
import { noop } from 'lodash'

const propTypes = {
  rootCauseSelection: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handlePhotoUploadAttempt: PropTypes.func,
  shouldSetCustomFormWideError: PropTypes.bool,
  setCustomFormWideError: PropTypes.func,
  setFieldError: PropTypes.func,
}

const defaultProps = {
  rootCauseSelection: null,
  isSubmitting: false,
  handlePhotoUploadAttempt: noop,
  shouldSetCustomFormWideError: false,
  setCustomFormWideError: noop,
  setFieldError: noop,
}

function ConditionalRootCauseFields({
  rootCauseSelection,
  isSubmitting,
  handlePhotoUploadAttempt,
  shouldSetCustomFormWideError,
  setCustomFormWideError,
  setFieldError,
}) {
  const isDefective = rootCauseSelection == Types.ROOT_CAUSE_DEFECT
  if (
    !Types.ROOT_CAUSE_NEGLECT_OPTIONS.includes(rootCauseSelection) &&
    !isDefective
  )
    return <></>
  const labelType = isDefective ? 'Defect' : 'Abuse'
  const placeholderTypeText = isDefective ? 'Defect' : 'Abuse and neglect'
  const textLabel = `${labelType} Notes`
  const placeholderText = `${placeholderTypeText} notes. Note that this is a multi-line field.`
  const lowercaseLabel = labelType.toLowerCase()
  const photoLabel = `${labelType} Photo`
  const photoName = `${lowercaseLabel}Photos`
  const photoType = isDefective
    ? Types.PHOTO_TYPE.DEFECT
    : Types.PHOTO_TYPE.ABUSE
  const photoConstraintText = `Please upload 1 to 3 images of ${lowercaseLabel} related photos for this section`

  return (
    <>
      <Textarea
        name="abuseNeglectClaims"
        autoGrow
        counter
        label={textLabel}
        placeholder={placeholderText}
        type="textarea"
        maxlength={Types.ABUSE_NEGLECT_NOTES_CHARACTER_LIMIT}
        required
        requiredIndicator="*"
      />
      {isDefective && (
        <h3 style={{ marginBottom: '30px' }}>
          Disclaimer: Please take a photo of defective part serial number if
          possible and hold onto defective part for potential warranty claim.
        </h3>
      )}
      <PhotoUploadField
        constraintText={photoConstraintText}
        label={photoLabel}
        name={photoName}
        photoType={photoType}
        required
        requiredIndicator="*"
        isSubmitting={isSubmitting}
        onPhotoUploadAttempt={handlePhotoUploadAttempt}
        shouldSetCustomFormWideError={shouldSetCustomFormWideError}
        setCustomFormWideError={setCustomFormWideError}
        setFieldError={setFieldError}
      />
    </>
  )
}

ConditionalRootCauseFields.propTypes = exact(propTypes)
ConditionalRootCauseFields.defaultProps = defaultProps

export default ConditionalRootCauseFields
