import React, { useEffect } from 'react'
import exact from 'prop-types-exact'
import { useSubmitMessages } from 'utils'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

const propTypes = {
  customFormWideError: PropTypes.string,
  setCustomFormWideError: PropTypes.func,
}
const defaultProps = {
  customFormWideError: null,
  setCustomFormWideError: noop,
}

// Note that this looks like it could just be a hook, but we seem to only get useFormikContext inside of a Formik component,
// and if we put useFormikContext inside the props function or inside of a hook, we get an error that says "Cannot use hooks inside of a callback."
function FormWideErrorReporter({
  customFormWideError,
  setCustomFormWideError,
}) {
  const displaySubmitFailure = useSubmitMessages().displaySubmitFailure

  const { isSubmitting, isValid, isValidating, submitCount, errors } =
    useFormikContext()

  useEffect(() => {
    if (customFormWideError) {
      displaySubmitFailure({ customFormWideError })
      setCustomFormWideError(null)
    }
    if (isValid || isValidating || !isSubmitting) return

    displaySubmitFailure(errors)
  }, [
    isSubmitting,
    isValid,
    isValidating,
    submitCount,
    errors,
    displaySubmitFailure,
    customFormWideError,
    setCustomFormWideError,
  ])

  return null
}

FormWideErrorReporter.propTypes = exact(propTypes)
FormWideErrorReporter.defaultProps = defaultProps

export default React.memo(FormWideErrorReporter)
