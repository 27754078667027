import {
  createDeleteRequest,
  createPostRequest,
  createRequest,
  createPatchRequest,
} from 'lp-redux-api'

/*** UNAUTHENTICATED REQUESTS */

export const authenticateUser = createPostRequest(
  'AUTHENTICATE_USER',
  (credentials) => ({
    url: '/api/v1/login',
    body: { user: credentials },
  })
)

export const requestPasswordReset = createPostRequest(
  'REQUEST_RESET_PASSWORD',
  (credentials) => ({
    url: '/api/v1/password',
    body: { user: credentials },
  })
)

export const resetPassword = createPatchRequest(
  'RESET_PASSWORD',
  (passwordParams) => ({
    url: '/api/v1/password',
    body: { user: passwordParams },
  })
)

export const createAccount = createPatchRequest(
  'CREATE_ACCOUNT',
  (passwordParams) => ({
    url: '/api/v1/invitation',
    body: { user: passwordParams },
  })
)

/*** AUTHENTICATED REQUESTS */

export const fetchCurrentUser = createRequest('FETCH_CURRENT_USER', () => ({
  url: '/api/v1/current_user',
  authenticated: true,
}))

export const logOutUser = createDeleteRequest('LOG_OUT', () => ({
  url: '/api/v1/logout',
  authenticated: true,
}))

export const fetchWorkOrder = createRequest('FETCH_WORK_ORDER', (id) => ({
  url: `/api/v1/work_orders/${id}`,
  authenticated: true,
}))

export const searchWorkOrder = createRequest('SEARCH_WORK_ORDER', (name) => ({
  url: `/api/v1/work_orders/search/${name}`,
  authenticated: true,
}))

export const createTrip = createPostRequest(
  'CREATE_TRIP',
  (data, coordinates) => ({
    url: '/api/v1/trips',
    body: { trip: data, coordinates },
    authenticated: true,
  })
)

export const updateTrip = createPatchRequest(
  'UPDATE_TRIP',
  (id, data, coordinates) => ({
    url: `/api/v1/trips/${id}`,
    body: { trip: data, coordinates },
    authenticated: true,
  })
)

export const createTripPhoto = createPostRequest(
  'CREATE_TRIP_PHOTO',
  (id, photo) => ({
    url: `/api/v1/trips/${id}/trip_photos`,
    body: { photo },
    authenticated: true,
  })
)

export const deleteTripPhoto = createDeleteRequest(
  'DELETE_TRIP_PHOTO',
  (id, photoId) => ({
    url: `/api/v1/trips/${id}/trip_photos/${photoId}`,
    authenticated: true,
  })
)

export const createQuestionnaireResponses = createPostRequest(
  'CREATE_QUESTIONNAIRE_RESPONSES',
  (tripId, questionResponses, questionnaireType) => ({
    url: `/api/v1/trips/${tripId}/question_responses`,
    body: { questionResponses, questionnaireType },
    authenticated: true,
  })
)
