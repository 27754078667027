import fieldNameFromTitle from './fieldNameFromTitle'
import { isNil } from 'lodash'

/* eslint-disable no-unused-vars */
export default function decorateQuestionnaireWithFieldNames(questionnaire) {
  const questions = questionnaire.questions
  // Questions can be a blank array, just not null / undefined
  if (isNil(questions)) {
    throw new Error('Questions must be defined')
  }

  const formattedQuestions = questions.map((question) => ({
    ...question,
    fieldName: fieldNameFromTitle(question?.title),
  }))

  return {
    ...questionnaire,
    questions: formattedQuestions,
  }
}
