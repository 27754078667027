import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  capitalize,
  castArray,
  first,
  isNil,
  isString,
  noop,
  partition,
  startCase,
} from 'lodash'
import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonSpinner,
  IonThumbnail,
} from '@ionic/react'
import { addOutline, closeOutline } from 'ionicons/icons'
import classnames from 'classnames'
import { useField } from 'formik'
import LabeledField from './LabeledField'
import * as Types from 'types'
import { formatApiErrorMessages, generateInputErrorId, isPresent } from 'utils'

const propTypes = {
  accept: PropTypes.string,
  capture: PropTypes.string,
  constraintText: PropTypes.string,
  hidePreview: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onFilesReadyToUpload: PropTypes.func,
  onRemove: PropTypes.func,
  isSubmitting: PropTypes.bool,
  thumbnail: PropTypes.string,
  field: Types.field,
  hideErrorLabel: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.string,
  disabled: PropTypes.bool,
  onPhotoUploadAttempt: PropTypes.func,
  shouldSetCustomFormWideError: PropTypes.bool,
  setCustomFormWideError: PropTypes.func,
  setFieldError: PropTypes.func,
}
const defaultProps = {
  constraintText: '',
  hidePreview: false,
  multiple: false,
  onFilesReadyToUpload: noop,
  onRemove: noop,
  updateErrors: null,
  updating: false,
  hideErrorLabel: false,
  required: true,
  requiredIndicator: '*',
  disabled: false,
  onPhotoUploadAttempt: noop,
  shouldSetCustomFormWideError: false,
  setCustomFormWideError: noop,
  setFieldError: noop,
}

//
// FileInput supports selection of a single photo or multiple photos. The
// "multiple" prop indicates that multiple photos will be maintained by this
// component. Note that in either the single or multiple case, the photos are
// stored as an array in the Redux store.
//
// FileInput suppports two different modes of file upload:
//   1. Upload as soon as a user selects files to upload. In this case the
//      provided onFilesReadyToUpload callback will be invoked to do the upload.
//   2. Upload as part of form submission. In this case, the "isSubmitting" prop
//      is set to "true".
//
// In either scenario, when FileInput is in the process of changing, the
// underlying <input> will be disabled and the rendered input container will
// change its icon to a spinner to indiciate that the file upload is in progress.
// Any errors encountered during the change process are available in the
// "updateErrors" prop. Note that form-wide errors take precedence over
// update errors.
function FileInput(props) {
  const {
    accept,
    capture,
    constraintText,
    hidePreview,
    multiple,
    onFilesReadyToUpload,
    onRemove,
    isSubmitting,
    thumbnail,
    field,
    hideErrorLabel,
    required,
    requiredIndicator,
    disabled,
    label,
    onPhotoUploadAttempt,
    shouldSetCustomFormWideError,
    setCustomFormWideError,
    setFieldError,
  } = props
  const { name, value } = field
  const useFieldProps = useField(name)
  const meta = useFieldProps[1]
  const helpers = useFieldProps[2]
  const setValue = helpers.setValue

  const [errors, setErrors] = useState(null)
  const [updating, setUpdating] = useState(false)
  const inputRef = useRef()

  const clearFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const removeFile = useCallback(
    async (fileId) => {
      const [removedFiles, remainingFiles] = partition(
        value,
        ({ id }) => id === fileId
      )
      const removedFile = first(removedFiles)

      try {
        setUpdating(true)
        await onRemove(removedFile)

        // If all files have been removed, then reset the native input
        if (remainingFiles.length === 0) clearFileInput()

        setErrors(null)

        setValue(remainingFiles)
      } catch (error) {
        const formattedErrors = formatError(error)

        if (onRemove === noop) {
          setErrors(formattedErrors)
          return
        }

        setErrors(
          `${capitalize(startCase(name))} failed to remove file: ${
            removedFile?.name
          }. Please contact customer support. Reported error: [${formattedErrors}]`
        )
      } finally {
        setUpdating(false)
      }
    },
    [value, onRemove, setValue, name]
  )

  const inputMeta = setInputErrors(meta, errors)
  const values = castFormValueToArray(value)
  const files = values.length > 0 ? values : []
  const displayPreviewPanel = !hidePreview && isPresent(first(files))
  const changing = updating || isSubmitting
  return (
    <LabeledField
      className="input-container fileinput-container"
      {...{
        meta: inputMeta,
        name,
        hideErrorLabel,
        label,
        required,
        requiredIndicator,
        disabled,
      }}
    >
      <p className="description body-small">{constraintText}</p>

      {displayPreviewPanel && (
        <IonList className="all-fileinput-previews-container" lines="none">
          {files.map((file) => (
            <IonItem className="fileinput-preview-container" key={file.id}>
              <RenderPreview {...{ file, slot: 'start', thumbnail }} />
              <IonButton
                disabled={changing}
                aria-label={`Remove ${file?.name}`}
                fill="clear"
                onClick={() => removeFile(file.id)}
                slot="end"
              >
                <IonIcon icon={closeOutline} slot="icon-only" size="small" />
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      )}

      <div className={classnames('native-fileinput-container', { changing })}>
        {changing ? (
          <IonSpinner name="lines-small" />
        ) : (
          <IonIcon icon={addOutline} size="large" />
        )}
        <input
          {...{
            accept,
            capture,
            disabled: changing,
            id: name,
            multiple,
            name,
            // force onChange to fire _every_ time (use case: attempting to upload the same file after a failure)
            onClick: clearFileInput,
            onChange: async (e) => {
              setUpdating(true)

              const files = [...e.target.files]
              const newFiles = removeExistingFiles(files)
              // New files are assigned an identifier (UUID) to ensure that
              // they can be distinguished even if they have the same file name.
              // This id is also used as the "key" when uploaded images are
              // rendered for preview.
              const newFilesWithId = assignFileIds(newFiles)
              const newFilesToUpload = multiple
                ? newFilesWithId
                : newFilesWithId.slice(0, 1)

              try {
                const uploadedPhotos = await onFilesReadyToUpload(
                  newFilesToUpload
                )
                setErrors(null)
                setValue(
                  multiple ? [...values, ...uploadedPhotos] : uploadedPhotos
                )
              } catch (error) {
                const formattedErrors = formatError(error)
                if (onFilesReadyToUpload === noop) {
                  setErrors(formattedErrors)
                  return
                }

                const nonUploadedFileNames = newFiles.map(({ name }) => name)

                if (shouldSetCustomFormWideError) {
                  // We get rid of the error on the Formik form field
                  // in order to display a custom error message in a Toast
                  setFieldError(name, null)
                  setCustomFormWideError(
                    'There was an error uploading your photo. Please try to upload photos for each photo field, but if you are unable to, you should be able to proceed after at least attempting to upload a photo.'
                  )
                } else {
                  setErrors(
                    `${capitalize(
                      startCase(name)
                    )} failed to upload the following files: ${nonUploadedFileNames.join(
                      ', '
                    )}. Please retry uploading these files. Reported error: [${formattedErrors}]`
                  )
                }
              } finally {
                setUpdating(false)
                onPhotoUploadAttempt(name)
              }
            },
            ref: inputRef,
            'aria-describedby': hasInputError(meta)
              ? generateInputErrorId(name)
              : null,
            type: 'file',
          }}
        />
      </div>
    </LabeledField>
  )
}

function RenderPreview({ file, slot, thumbnail }) {
  return (
    <>
      {isImageType(file) && (
        <IonThumbnail slot={slot}>
          <img alt={file?.name} src={file?.url || thumbnail} />
        </IonThumbnail>
      )}
      <p>{file?.name}</p>
    </>
  )
}

function castFormValueToArray(value) {
  if (!value) return []
  return castArray(value)
}

function hasInputError(meta) {
  const { invalid, touched } = meta
  return invalid && touched
}

function isImageType(file) {
  if (!file) return false
  return file.type && file.type.includes('image')
}

// Do not reload files that have been successfully uploaded. Successfully
// uploaded files are identified by the presence of an "id" field.
function removeExistingFiles(newFiles) {
  return newFiles.filter(({ id }) => isNil(id))
}

function assignFileIds(newFiles) {
  return newFiles.map((file) => {
    file.id = crypto.randomUUID()

    return file
  })
}

function setInputErrors(meta, fieldWideErrors) {
  // fieldWideErrors can be API errors returned from the server
  if (isPresent(fieldWideErrors)) {
    const allFormWideErrors = isString(fieldWideErrors)
      ? fieldWideErrors
      : fieldWideErrors.message
    let allErrors = ''

    if (meta.error) {
      allErrors = [meta.error, allFormWideErrors].join('. ')
    } else {
      allErrors = allFormWideErrors
    }

    return {
      ...meta,
      error: allErrors,
      touched: true,
    }
  }

  return meta
}

function formatError(error) {
  const apiErrors = error?.errors

  if (apiErrors) return formatApiErrorMessages(apiErrors)
  const formattedErrorMessage =
    typeof error === 'string' ? error : `${error?.name}: ${error?.message}`
  return `A processing error occurred. Please contact customer support. (${formattedErrorMessage}. Connected? ${navigator.onLine})`
}

FileInput.propTypes = propTypes
FileInput.defaultProps = defaultProps

export default FileInput
